<template>
  <div class="login">
    <div class="login-container">
      <img alt
           src="../assets/images/ic_login_logo.png">
      <div class="login-form">
        <p>用户登录</p>
        <el-form ref="loginForm"
                 :model="loginForm">
          <el-form-item prop="account"
                        :rules="loginRules.account">
            <el-input v-model="loginForm.account"
                      class="login-input"
                      placeholder="账号/手机号"></el-input>
          </el-form-item>
          <el-form-item prop="password"
                        :rules="loginRules.password">
            <el-input v-model="loginForm.password"
                      class="login-input"
                      placeholder="密码"
                      type="password"
                      @keyup.enter.native="loginClick"></el-input>
          </el-form-item>
          <el-button type="success"
                     class="login-btn"
                     :loading="loginLoading"
                     @click="loginClick">登录
          </el-button>
        </el-form>
        <!--        <div class="login-control">-->
        <!--          <el-checkbox v-model="isRemember">下次自动登录</el-checkbox>-->
        <!--          <div class="login-control-right">-->
        <!--            <span @click="returnLogin(0)">忘记密码</span>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="login-footer">
        <p>Copyright&copy2020深圳市乐乐米信息技术有限公司版权所有</p>
        <!--          <p>粤ICP备18022857号-2</p>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      isRemember: false,
      loginLoading: false,
      loginForm: {
        // account: "13794455271",
        account: "",
        // password: "123456",
        password: "",
      },
      loginRules: {
        account: [
          {
            required: true,
            message: "请输入用户名/手机号/身份ID",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      unreadMessage: [],
    };
  },
  methods: {
    loginClick() {
      const that = this;
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          sessionStorage.clear();
          this.$get("/login", {
            username: this.loginForm.account,
            password: this.loginForm.password,
          })
            .then((res) => {
              that.loginLoading = false;
              if (res.code === "5000") {
                sessionStorage.clear();
                res.data[0].courses = res.data[0].courses || [];
                res.data[0].gradeList = res.data[0].gradeList || [];
                sessionStorage.setItem("token", res.data[0].Authorization);
                sessionStorage.setItem("userInfo", JSON.stringify(res.data[0]));
                sessionStorage.setItem("nowSemester", res.data[0].NowSemester);
                sessionStorage.setItem(
                  "semesterList",
                  JSON.stringify(res.data[0].semesters)
                );
                that.$router.push("/system");
                that.$store.commit(
                  "logn",
                  JSON.parse(sessionStorage.getItem("userInfo"))
                );
                that.loadDictionaries();
                that.loadUnreadMessage();
              } else {
                that.$message.error(res.message);
              }
            })
            .catch((error) => {
              that.$message.error(error.message);
              that.loginLoading = false;
            });
        }
      });
    },
    loadDictionaries() {
      this.$get("/dict/queryDict", {})
        .then((res) => {
          sessionStorage.setItem("dictionaries", JSON.stringify(res.data));
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    viewMessage(row) {
      const that = this;
      this.$post("/base/updateNewsStatus.do", { id: row.id })
        .then((res) => {})
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
      this.$router.push({ name: row.path });
    },
    loadUnreadMessage() {
      const that = this;
      this.$post("/base/queryNotNews.do", {})
        .then((res) => {
          res.data.data.forEach((message) => {
            setTimeout(() => {
              this.$notify({
                title: "新通知",
                message: message.title,
                duration: 5000,
                onClick() {
                  that.viewMessage(message);
                },
              });
            }, 500);
          });
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.el-input {
  width: 100%;
}

.login {
  background: url("../assets/images/login_bg3.jpg") no-repeat center;
  width: 100vw;
  height: 100vh;
}

.login-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  text-align: center;
}

.login-container > img {
  height: 70px;
}

.login-form {
  box-sizing: border-box;
  width: 450px;
  border-radius: 4px;
  background-color: #ffffff;
  margin: 40px auto 75px auto;
  padding: 50px;
  box-shadow: 0 0 20px 5px #adadad;
}

.login-form > p {
  color: #333333;
  font-size: 24px;
  margin-bottom: 35px;
}

.el-input__inner {
  height: 42px;
  background-color: #fafafa;
}

.login-btn {
  width: 100%;
  height: 42px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 20px;
  font-size: 15px;
}

.login-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-control-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #999999;
  width: 120px;
}

.login-control-right > span:hover {
  opacity: 0.7;
  cursor: pointer;
}

.login-footer {
  color: #4c4c4c90;
  margin-top: 60px;
  line-height: 1.8;
  letter-spacing: 1px;
  font-size: 14px;
}
</style>
