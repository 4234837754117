/**
 * Created by Yzh
 * Datetime: 2021/4/16 14:54
 *
 */
import axios from "axios";
import qs from 'qs'
import router from "@/router";

axios.defaults.baseURL = '/api';

axios.interceptors.request.use(
    config => {
        if (config.headers["JSON-TYPE"]) {
            config.headers = {
                "Content-Type": "application/json"
            };
        } else {
            config.headers = {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            };
        }
        if (sessionStorage.getItem("token")) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = window.sessionStorage.getItem("token");
        }
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error && error.response) {
            if (error.response.config.url.indexOf('login') === -1) {
                switch (error.response.status) {
                    case 401:
                        router.replace({
                            path: "/"
                        });
                        return Promise.reject(new Error("登录过期"));
                }
            }
            return Promise.reject(error.response);
        }
        return Promise.reject(error);
    }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(response => {
                if (response.data.type === 1) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * 封装put方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .put(url, '', {
                params: params
            })
            .then(response => {
                if (response.data.type === 1) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * 封装delete方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function deletes(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .delete(url, {
                params: params
            })
            .then(response => {
                if (response.data.type === 1) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param params
 * @returns {Promise}
 */

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, qs.stringify(params))
            .then(response => {
                if (response.data.type === 1) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(err => {
                reject(new Error(err));
            });
    });
}

/**
 * 封装postJson方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postJson(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    "Content-Type": "application/json;",
                    "JSON-TYPE": "application/json;"
                }
            })
            .then(response => {
                if (response.data.type === 1) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(err => {
                reject(err.data);
            });
    });
}


/**
 * 封装getFile方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function getFile(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
                responseType: "blob"
            })
            .then(response => {
                if (response.data.type === 1) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}


/**
 * 封装postFile方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postFile(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json;",
                    "JSON-TYPE": "application/json;"
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
}
