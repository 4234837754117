import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
        unreadMessage: -1
    },
    mutations: {
        logn(state, newUserInfo) {
            state.userInfo = newUserInfo
        },
        modifyUserName(state, name) {
            state.userInfo.username = name
            let newUserInfo = JSON.parse(sessionStorage.getItem("userInfo"))
            newUserInfo.username = name
            sessionStorage.setItem("userInfo", JSON.stringify(newUserInfo))
        },
        modifySchoolName(state, name) {
            state.userInfo.sname = name
            let newUserInfo = JSON.parse(sessionStorage.getItem("userInfo"))
            newUserInfo.sname = name
            sessionStorage.setItem("userInfo", JSON.stringify(newUserInfo))
        },
        modifyLogo(state, logo) {
            state.userInfo.logo = logo
            let newUserInfo = JSON.parse(sessionStorage.getItem("userInfo"))
            newUserInfo.logo = logo
            sessionStorage.setItem("userInfo", JSON.stringify(newUserInfo))
        },
        modifyUserIcon(state, icon) {
            state.userInfo.icon = icon
            let newUserInfo = JSON.parse(sessionStorage.getItem("userInfo"))
            newUserInfo.icon = icon
            sessionStorage.setItem("userInfo", JSON.stringify(newUserInfo))
        },
        updateUnreadMessage(state, newsNumber) {
            state.unreadMessage = newsNumber
        }
    },
    actions: {

    },
    modules: {

    }
})
