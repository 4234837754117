import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

Vue.use(VueRouter)

//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/system',
    component: () => import(/* webpackChunkName: "about" */ '../views/menuFrame.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
        meta: {name: '首页'}
      },

      {
        path: 'educationalTeaching/educational/termManage',
        name: 'termManage',
        component: () => import('../views/educationalTeaching/educational/termManage.vue'),
        meta: {name: '学期管理'}
      },
      {
        path: 'educationalTeaching/educational/gradeClassManage',
        name: 'gradeClassManage',
        component: () => import('../views/educationalTeaching/educational/gradeClassManage.vue'),
        meta: {name: '年级/班级管理'}
      },
      {
        path: 'roleManage/roleManager',
        name: 'roleManager',
        component: () => import('../views/roleManage/roleManager.vue'),
        meta: {name: '角色权限管理'}
      },
      {
        path: 'educationalTeaching/educational/classManage',
        name: 'classManage',
        component: () => import('../views/educationalTeaching/educational/classManage.vue'),
        meta: {name: '教室管理'}
      },
      {
        path: 'educationalTeaching/educational/teacherManage',
        name: 'teacherManage',
        component: () => import('../views/educationalTeaching/educational/teacherManage.vue'),
        meta: {name: '教师管理'}
      },
      {
        path: 'roleManage/authorityManage',
        name: 'authorityManage',
        component: () => import('../views/roleManage/authorityManage.vue'),
        meta: {name: '权限管理'}
      },
      {
        path: 'roleManage/moduleManagement',
        name: 'moduleManagement',
        component: () => import('../views/roleManage/moduleManagement.vue'),
        meta: {name: '模块权限管理'}
      },
      {
        path: 'educationalTeaching/educational/studentManage',
        name: 'studentManage',
        component: () => import('../views/educationalTeaching/educational/studentManage.vue'),
        meta: {name: '学生管理'}
      },
      {
        path: 'educationalTeaching/educational/teachingPatro',
        name: 'teachingPatro',
        component: () => import('../views/educationalTeaching/educational/teachingPatro.vue'),
        meta: {name: '教学巡查'}
      },
      {
        path: 'educationalTeaching/educational/schoolCalendar',
        name: 'schoolCalendar',
        component: () => import('../views/educationalTeaching/educational/schoolCalendar.vue'),
        meta: {name: '校历'}
      },
      {
        path: 'educationalTeaching/educational/teachingPatrolDetails',
        name: 'teachingPatrolDetails',
        component: () => import('../views/educationalTeaching/educational/teachingPatrolDetails.vue'),
        meta: {name: '教学巡查详情'}
      },
      {
        path: 'educationalTeaching/educational/subjectsManage',
        name: 'subjectsManage',
        component: () => import('../views/educationalTeaching/educational/subjectsManage.vue'),
        meta: {name: '学科管理'}
      },
      {
        path: 'educationalTeaching/educational/departmentManage',
        name: 'departmentManage',
        component: () => import('../views/educationalTeaching/educational/departmentManage.vue'),
        meta: {name: '部门管理'}
      },
      {
        path: 'educationalTeaching/educational/timeTableManage',
        name: 'timeTableManage',
        component: () => import('../views/educationalTeaching/educational/timeTableManage.vue'),
        meta: {name: '课表管理'}
      },
      {
        path: 'educationalTeaching/individualization/individualizationTable',
        name: 'individualizationTable',
        component: () => import('../views/educationalTeaching/individualization/individualizationTable.vue'),
        meta: {name: '个别化教育资料'}
      },
      {
        path: 'personalCenter',
        name: 'personalCenter',
        component: () => import('../views/personalCenter.vue'),
        meta: {name: '个人中心'}
      },
      {
        path: 'officeManagement/schoolManage/addressList',
        name: 'addressList',
        component: () => import('../views/officeManagement/schoolManage/addressList.vue'),
        meta: {name: '通讯录'}
      },
      {
        path: 'annualExamineManage/annualExamine',
        name: 'annualExamine',
        component: () => import('../views/annualExamineManage/annualExamine.vue'),
        meta: {name: '年度考核'}
      },
      {
        path: 'annualExamineManage/assessmentDetails',
        name: 'assessmentDetails',
        component: () => import('../views/annualExamineManage/assessmentDetails.vue'),
        meta: {name: '考核详情'}
      },
      {
        path: 'annualExamineManage/templateDetails',
        name: 'templateDetails',
        component: () => import('../views/annualExamineManage/templateDetails.vue'),
        meta: {name: '问卷详情'}
      },
      {
        path: 'annualExamineManage/dataSummary',
        name: 'dataSummary',
        component: () => import('../views/annualExamineManage/dataSummary.vue'),
        meta: {name: '数据汇总'}
      },
      {
        path: 'annualExamineManage/score',
        name: 'score',
        component: () => import('../views/annualExamineManage/score.vue'),
        meta: {name: '评分'}
      },
      {
        path: 'annualExamineManage/scoringOperation',
        name: 'scoringOperation',
        component: () => import('../views/annualExamineManage/scoringOperation.vue'),
        meta: {name: '评分操作'}
      },
      {
        path: 'annualExamineManage/questionnairePage',
        name: 'questionnairePage',
        component: () => import('../views/annualExamineManage/questionnairePage.vue'),
        meta: {name: '评分页面'}
      },
      {
        path: 'messageServer/messageManagement',
        name: 'messageManagement',
        component: () => import('../views/messageServer/messageManagement.vue'),
        meta: {name: '统一消息管理'}
      },
      {
        path: 'messageServer/feedbackManagement',
        name: 'feedbackManagement',
        component: () => import('../views/messageServer/feedbackManagement.vue'),
        meta: {name: '意见反馈管理'}
      },
      // {
      //     path: 'feedbackManagement',
      //     name: 'feedbackManagement',
      //     component: () => import('../views/feedbackManagement.vue'),
      //     meta: { name: '意见反馈管理' }
      // },
      {
        path: 'educationalTeaching/educational/schoolMessage',
        name: 'schoolMessage',
        component: () => import('../views/educationalTeaching/educational/schoolMessage.vue'),
        meta: {name: '学校信息'}
      },
      {
        path: 'annualExamineManage/oneTicketVeto',
        name: 'oneTicketVeto',
        component: () => import('../views/annualExamineManage/oneTicketVeto.vue'),
        meta: {name: '一票否决汇总'}
      },
      {
        path: 'educationalTeaching/educational/courseAdjustmentManage',
        name: 'courseAdjustmentManage',
        component: () => import('../views/educationalTeaching/educational/courseAdjustmentManage.vue'),
        meta: {name: '代课管理'}
      },
      {
        path: 'annualExamineManage/scoreDetails',
        name: 'scoreDetails',
        component: () => import('../views/annualExamineManage/scoreDetails.vue'),
        meta: {name: '分数详情'}
      },
      {
        path: 'officeManagement/schoolManage/leave',
        name: 'leave',
        component: () => import('../views/officeManagement/schoolManage/leave.vue'),
        meta: {name: '请假'}
      },
      {
        path: 'officeManagement/schoolManage/temporaryLeave',
        name: 'temporaryLeave',
        component: () => import('../views/officeManagement/schoolManage/temporaryLeave.vue'),
        meta: {name: '临时假'}
      },
      {
        path: 'officeManagement/schoolManage/useCar',
        name: 'useCar',
        component: () => import('../views/officeManagement/schoolManage/useCar.vue'),
        meta: {name: '用车'}
      },
      {
        path: 'officeManagement/schoolManage/notice',
        name: 'notice',
        component: () => import('../views/officeManagement/schoolManage/notice.vue'),
        meta: {name: '通知公告'}
      },
      {
        path: 'officeManagement/schoolManage/notificationManagement',
        name: 'notificationManagement',
        component: () => import('../views/officeManagement/schoolManage/notificationManagement.vue'),
        meta: {name: '通知公告管理'}
      },
      {
        path: 'officeManagement/schoolManage/editNotification',
        name: 'editNotification',
        component: () => import('../views/officeManagement/schoolManage/editNotification.vue'),
        meta: {name: '编辑通知'}
      },
      {
        path: 'officeManagement/schoolManage/noticePage',
        name: 'noticePage',
        component: () => import('../views/officeManagement/schoolManage/noticePage.vue'),
        meta: {name: '通知详情'}
      },
      {
        path: 'homeSchoolInteraction/studentLeaveAudit',
        name: 'studentLeaveAudit',
        component: () => import('../views/homeSchoolInteraction/studentLeaveAudit.vue'),
        meta: {name: '学生请假审核'}
      },
      {
        path: 'homeSchoolInteraction/publishHomework',
        name: 'publishHomework',
        component: () => import('../views/homeSchoolInteraction/publishHomework.vue'),
        meta: {name: '发布家庭作业'}
      },
      {
        path: 'homeSchoolInteraction/bookingManagement',
        name: 'bookingManagement',
        component: () => import('../views/homeSchoolInteraction/bookingManagement.vue'),
        meta: {name: '预约管理'}
      },
      {
        path: 'educationalTeaching/awardManagement/awardWiningTeachers',
        name: 'awardWiningTeachers',
        component: () => import('../views/educationalTeaching/awardManagement/awardWiningTeachers.vue'),
        meta: {name: '获奖教师'}
      },
      {
        path: 'educationalTeaching/awardManagement/awardWiningStudents',
        name: 'awardWiningStudents',
        component: () => import('../views/educationalTeaching/awardManagement/awardWiningStudents.vue'),
        meta: {name: '获奖学生'}
      },
      {
        path: 'officeManagement/schoolManage/reportForRepair',
        name: 'reportForRepair',
        component: () => import('../views/officeManagement/schoolManage/reportForRepair.vue'),
        meta: {name: '报修'}
      },
      {
        path: 'officeManagement/documentManagement/sendingManagement',
        name: 'sendingManagement',
        component: () => import('../views/officeManagement/documentManagement/sendingManagement.vue'),
        meta: {name: '发文管理'}
      },
      {
        path: 'officeManagement/documentManagement/receivingManagement',
        name: 'receivingManagement',
        component: () => import('../views/officeManagement/documentManagement/receivingManagement.vue'),
        meta: {name: '收文管理'}
      },
      {
        path: 'officeManagement/planSummary/planSummary',
        name: 'planSummary',
        component: () => import('../views/officeManagement/planSummary/planSummary.vue'),
        meta: {name: '计划总结'}
      },
      {
        path: 'officeManagement/documentManagement/viewFile',
        name: 'viewFile',
        component: () => import('../views/officeManagement/documentManagement/viewFile.vue'),
        meta: {name: '文件查看'}
      },
      {
        path: 'educationalTeaching/awardManagement/awardWiningSchools',
        name: 'awardWiningSchools',
        component: () => import('../views/educationalTeaching/awardManagement/awardWiningSchools.vue'),
        meta: {name: '学校获奖'}
      },
      {
        path: 'educationalTeaching/awardManagement/classAward',
        name: 'classAward',
        component: () => import('../views/educationalTeaching/awardManagement/classAward.vue'),
        meta: {name: '班级获奖'}
      },
      {
        path: 'educationalTeaching/awardManagement/otherAward',
        name: 'otherAward',
        component: () => import('../views/educationalTeaching/awardManagement/otherAward.vue'),
        meta: {name: '其他获奖'}
      },
      {
        path: 'roleManage/examinerManagement',
        name: 'examinerManagement',
        component: () => import('../views/roleManage/examinerManagement.vue'),
        meta: {name: '审批人管理'}
      },
      {
        path: 'educationalTeaching/teachingResourceLibrary/teachingManagement',
        name: 'teachingManagement',
        component: () => import('../views/educationalTeaching/teachingResourceLibrary/teachingManagement.vue'),
        meta: {name: '教案管理'}
      },
      {
        path: 'educationalTeaching/teachingResourceLibrary/coursewareManagement',
        name: 'coursewareManagement',
        component: () => import('../views/educationalTeaching/teachingResourceLibrary/coursewareManagement.vue'),
        meta: {name: '课件管理'}
      },
      {
        path: 'educationalTeaching/teachingResourceLibrary/microClass',
        name: 'microClass',
        component: () => import('../views/educationalTeaching/teachingResourceLibrary/microClass.vue'),
        meta: {name: '微课'}
      },
      {
        path: 'educationalTeaching/teachingResourceLibrary/teachingPaper',
        name: 'teachingPaper',
        component: () => import('../views/educationalTeaching/teachingResourceLibrary/teachingPaper.vue'),
        meta: {name: '教学论文'}
      },
      {
        path: 'educationalTeaching/teachingResourceLibrary/other',
        name: 'other',
        component: () => import('../views/educationalTeaching/teachingResourceLibrary/other.vue'),
        meta: {name: '其他'}
      },
      {
        path: 'educationalTeaching/automaticCourseArrangementManagement/courseAndTimeManagement',
        name: 'courseAndTimeManagement',
        component: () => import('../views/educationalTeaching/automaticCourseArrangementManagement/courseAndTimeManagement.vue'),
        meta: {name: '学科学时管理'}
      },
      {
        path: 'educationalTeaching/automaticCourseArrangementManagement/templateCourseTime',
        name: 'templateCourseTime',
        component: () => import('../views/educationalTeaching/automaticCourseArrangementManagement/templateCourseTime.vue'),
        meta: {name: '学科学时模板'}
      },
      {
        path: 'educationalTeaching/automaticCourseArrangementManagement/positionCourseTimeManagement',
        name: 'positionCourseTimeManagement',
        component: () => import('../views/educationalTeaching/automaticCourseArrangementManagement/positionCourseTimeManagement.vue'),
        meta: {name: '职位课时管理'}
      },
      {
        path: 'educationalTeaching/automaticCourseArrangementManagement/templatePositionCourseTime',
        name: 'templatePositionCourseTime',
        component: () => import('../views/educationalTeaching/automaticCourseArrangementManagement/templatePositionCourseTime.vue'),
        meta: {name: '职位课时模板'}
      },
      {
        path: 'educationalTeaching/automaticCourseArrangementManagement/courseTable',
        name: 'courseTable',
        component: () => import('../views/educationalTeaching/automaticCourseArrangementManagement/courseTable.vue'),
        meta: {name: '课程总表'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/subjectPlanSummary',
        name: 'subjectPlanSummary',
        component: () => import('../views/educationalTeaching/educationScientificCenter/subjectPlanSummary.vue'),
        meta: {name: '学科组计划'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/scoringSystem',
        name: 'scoringSystem',
        component: () => import('../views/educationalTeaching/educationScientificCenter/scoringSystem.vue'),
        meta: {name: '评分系统'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/publishTaskDetails',
        name: 'publishTaskDetails',
        component: () => import('../views/educationalTeaching/educationScientificCenter/publishTaskDetails.vue'),
        meta: {name: '发布任务详情'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/scoreDetail',
        name: 'scoreDetail',
        component: () => import('../views/educationalTeaching/educationScientificCenter/scoreDetail.vue'),
        meta: {name: '评分详情'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/finishedCondition',
        name: 'finishedCondition',
        component: () => import('../views/educationalTeaching/educationScientificCenter/finishedCondition.vue'),
        meta: {name: '完成情况'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/ranking',
        name: 'ranking',
        component: () => import('../views/educationalTeaching/educationScientificCenter/ranking.vue'),
        meta: {name: '排名'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/openClass',
        name: 'openClass',
        component: () => import('../views/educationalTeaching/educationScientificCenter/openClass.vue'),
        meta: {name: '公开课'}
      },
      {
        path: 'educationalTeaching/educationScientificCenter/openClassScoring',
        name: 'openClassScoring',
        component: () => import('../views/educationalTeaching/educationScientificCenter/openClassScoring.vue'),
        meta: {name: '公开课评分详情'}
      },
      {
        path: 'partyAffairsConstruction/InformationPublish',
        name: 'InformationPublish',
        component: () => import('../views/partyAffairsConstruction/InformationPublish.vue'),
        meta: {name: '信息发布'}
      },
      {
        path: 'partyAffairsConstruction/activityManagement',
        name: 'activityManagement',
        component: () => import('../views/partyAffairsConstruction/activityManagement.vue'),
        meta: {name: '活动管理'}
      },
      {
        path: 'partyAffairsConstruction/TemplateReadCondition',
        name: 'TemplateReadCondition',
        component: () => import('../views/partyAffairsConstruction/TemplateReadCondition.vue'),
        meta: {name: '阅读状况'}
      },
      {
        path: 'moralEducationManagement/classCultureConstruction/register',
        name: 'register',
        component: () => import('../views/moralEducationManagement/classCultureConstruction/register.vue'),
        meta: {name: '登记'}
      },
      {
        path: 'moralEducationManagement/classCultureConstruction/viewPushData',
        name: 'viewPushData',
        component: () => import('../views/moralEducationManagement/classCultureConstruction/viewPushData.vue'),
        meta: {name: '详情'}
      },
      {
        path: 'moralEducationManagement/classCultureConstruction/assessment',
        name: 'assessment',
        component: () => import('../views/moralEducationManagement/classCultureConstruction/assessment.vue'),
        meta: {name: '考核'}
      },
      {
        path: 'moralEducationManagement/schoolCultureConstruction/schoolCultureConstruction',
        name: 'schoolCultureConstruction',
        component: () => import('../views/moralEducationManagement/schoolCultureConstruction/schoolCultureConstruction.vue'),
        meta: {name: '学校文化建设'}
      },
      {
        path: 'moralEducationManagement/classCultureConstruction/personalScoring',
        name: 'personalScoring',
        component: () => import('../views/moralEducationManagement/classCultureConstruction/personalScoring.vue'),
        meta: {name: '个人打分情况'}
      },
      {
        path: 'moralEducationManagement/classCultureConstruction/templateclassCultureSummaryScore',
        name: 'templateclassCultureSummaryScore',
        component: () => import('../views/moralEducationManagement/classCultureConstruction/templateclassCultureSummaryScore.vue'),
        meta: {name: '班级文化汇总个人打分情况'}
      },
      {
        path: 'moralEducationManagement/classCultureConstruction/templateEnvironmentalCultureScore',
        name: 'templateEnvironmentalCultureScore',
        component: () => import('../views/moralEducationManagement/classCultureConstruction/templateEnvironmentalCultureScore.vue'),
        meta: {name: '环境文化评比详情'}
      },
      {
        path: 'moralEducationManagement/routineFertilityManagement/activityManagement',
        name: 'routineFertilityManagementActivityManagement',
        component: () => import('../views/moralEducationManagement/routineFertilityManagement/activityManagement.vue'),
        meta: {name: '活动管理'}
      },
      {
        path: 'largeForceManagement/activityManagement',
        name: 'largeForceManagementActivityManagement',
        component: () => import('../views/largeForceManagement/activityManagement.vue'),
        meta: {name: '活动管理'}
      },
      // {
      //     path: 'moralEducationManagement/classCultureConstruction/classCultureDataSummary',
      //     name: 'classCultureDataSummary',
      //     component: () => import('../views/moralEducationManagement/classCultureConstruction/classCultureDataSummary.vue'),
      //     meta: { name: '班级文化数据汇总' }
      // },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
