import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/element-variables.scss'
import "./assets/style/element-ui.scss";

import { post, put, get, getFile, deletes, postJson, postFile, postJsonRaw } from "./utils/apiConfig"

Vue.use(ElementUI);


Vue.config.productionTip = false

//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$postJson = postJson;
Vue.prototype.$deletes = deletes;
Vue.prototype.$put = put;
Vue.prototype.$getFile = getFile;
Vue.prototype.$postFile = postFile;
Vue.prototype.$fileUploadDomain = "https://zhuxiang21.oss-cn-shenzhen.aliyuncs.com/";

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
